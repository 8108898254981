import { Card } from '@repo/ui/components/Card.js'
import { Outlet } from 'react-router'

export default function AuthLayout() {
  return (
    <div className="h-full flex flex-col justify-center items-center min-h-svh bg-gradient-to-br from-brand-950 to-brand-400 text-md">
      <Card className="min-w-96 max-w-lg shadow">
        <Outlet />
      </Card>
    </div>
  )
}
